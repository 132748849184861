import "./App.css";
import React, { useState, useEffect } from "react";
import Posts from "./components/Posts.js";
import NewPostForm from "./components/NewPostForm.js";
import { getPosts, addPost } from "./utils/RequestHandler.js";

function App() {
  const [posts, setPosts] = useState([]);

  const handleFormSubmit = async (newPost) => {
    console.log(newPost);
    const response = await addPost(newPost);
    if (response) {
      const newPosts = await getPosts();
      setPosts(newPosts);
    }
  };

  useEffect(() => {
    // get initial posts, this is only called once when App is initially rendered
    async function fetchData() {
      const response = await getPosts();
      setPosts(response);
    }
    fetchData();
  }, []);

  return (
    <div className="App">
      <Posts posts={posts} className="posts" />
      <NewPostForm className="newPosts" handleFormSubmit={handleFormSubmit} />
    </div>
  );
}

export default App;
