import React from "react";

const Posts = ({ posts }) => {
  console.log(posts);
  return (
    <div className="posts">
      {posts.map((item) => (
        <div key={item[0]}> {item[1]} </div>
      ))}
    </div>
  );
};

export default Posts;
