const baseUrl = "https://general-worker.soroush3.workers.dev/posts";

export const getPosts = async () => {
  const response = await fetch(baseUrl, {
    method: "GET",
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      return res.json();
    })
    .catch((error) => {
      console.log("Error retrieving posts");
      console.log(error);
      return [];
    });
  return response;
};

export const addPost = async (newPost) => {
  const response = await fetch(baseUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newPost),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      return true;
    })
    .catch((error) => {
      console.log("Error creating post");
      console.log(error);
      return false;
    });
  return response;
};
