import React, { useState } from "react";

const initialPost = {
  title: "",
  content: "",
};

const NewPostForm = ({ handleFormSubmit }) => {
  const [post, setPost] = useState({ ...initialPost });
  const { title, content } = post;

  const onChange = (e) => {
    const { name, value } = e.target;

    let newPost = { ...post };
    newPost[name] = value;

    setPost(newPost);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newPost = { ...post };
    handleFormSubmit(newPost);
    setPost({ ...initialPost });
  };

  return (
    <div className="postForm">
      <form
        id="newPostForm"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div>
          <input
            required
            onChange={onChange}
            id="postTitle"
            name="title"
            type="text"
            placeholder="Post Title"
            value={title}
          />
        </div>
        <div>
          <textarea
            required
            name="content"
            onChange={onChange}
            form="newPostForm"
            className="textBox"
            id="postTitle"
            type="text"
            placeholder="Post Content"
            value={content}
          />
        </div>
        <div>
          <button type="submit">Create Post</button>
        </div>
      </form>
    </div>
  );
};

export default NewPostForm;
